import React, {useState, useEffect, useContext, useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import PageFooter from './PageFooter';
import AffichagePanier from './AffichagePanier';
import CheminSecurise from './CheminSecurise';
import {ConnectionContext} from './ConnectionSystem';
import './App.css';

function PageAdmin() {
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	const [dateEncours, setDateencours] = useState('');
    const [setNewproduct, setNewp] = useState(false);
    const [cPage, setCpage] = useState(1);
	const [lPage, setLpage] = useState(4);
	const [totalPages, setTotalpages] = useState(0);
    const [dataBoutique, setDataboutique] = useState([]);
    const [dataCommandes, setDatacommandes] = useState([]);
    const [dataBoutiqueTotalpage, setDataboutiquetotalpage] = useState(0);
    //
    const [dataPanier, setDatapanier] = useState([]);
	//
    const [txtProduitSerial, setTxtproduitserial] = useState('');
    const [txtProduitNom, setTxtproduitnom] = useState('');
    const [txtProduitPrix, setTxtproduitprix] = useState('');
    const [txtProduitDetail, setTxtproduitdetail] = useState('');
    const [txtProduitStock, setTxtproduitstock] = useState('');
    const [txtProduitDossier, setTxtproduitdossier] = useState('');
    const [txtProduitDate, setTxtproduitdate] = useState('');
    const [txtImageprincipale, setImageprincipale] = useState(null);
    const [txtImage1, setImage1] = useState(null);
    const [txtImage2, setImage2] = useState(null);
    const [txtImage3, setImage3] = useState(null);
    const [txtImage4, setImage4] = useState(null);
    const refProduitSerial = useRef(null);
    const refProduitNom = useRef(null);
    const refProduitPrix = useRef(null);
    const refProduitDetail = useRef(null);
    const refProduitStock = useRef(null);
    const refProduitDossier = useRef(null);
    const refProduitDate = useRef(null);
    const refProduitImageprincipale = useRef(null);
    const refProduitImage1 = useRef(null);
    const refProduitImage2 = useRef(null);
    const refProduitImage3 = useRef(null);
    const refProduitImage4 = useRef(null);
    //
    const [sectionView, setSectionview] = useState(0);
	const navg = useNavigate();
	//
	useEffect(() => {
        getDataboutique();
        const intVall = setInterval(() => {
            setDateencours(formatDateFr(new Date()));
        }, 1000);      
	}, [cPage]);
    //
    const getDataboutique = async() => {
        let v = '';
        axios.get('https://api.hermannlepatriote.com/_010111SQL_/cataloguedeboutique', {params: {cPage, lPage, v}})
        .then(response => {
            switch (response.data.erreur) {
                case "ErreurBoutique":
                    alert('Erreur sur le catalogue'+response.data.message);
                case "SuccessBoutique":
                    return fillBoutiqueData(response.data.dataProduits, response.data.tpages, response.data.dataCommandes);
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur sur le catalogue',error);
        });
    }
    const enregistrerNewProduit = async() => {
        var datep = formatDateFr(new Date());
        axios.post('https://api.hermannlepatriote.com/_010111SQL_/enregistrernouveauproduit', {txtProduitSerial, txtProduitNom, txtProduitPrix, txtProduitDetail, txtProduitStock, txtImageprincipale, txtImage1, txtImage2, txtImage3, txtImage4, txtProduitDate})
        .then(response => {
            switch (response.data.erreur) {
                case "ErreurEnregistrerProduit":
                    return alert('Erreur de lenregistrement'+response.data.message);
                case "SuccessEnregistrerProduit":
                    return fillBoutiqueProduit(response.data.dataProduits);
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur de lenregistrement du produit', error);
        });
    };
    const clearformNewProduit = async() => {
        setTxtproduitserial('');
        setTxtproduitnom('');
        setTxtproduitprix('');
        setTxtproduitdetail('');
        setTxtproduitstock('');
        setTxtproduitdossier('');
        setTxtproduitdate('');
        setImageprincipale(null);
        setImage1(null);
        setImage2(null);
        setImage3(null);
        setImage4(null);
        refProduitImageprincipale.current.value = null;
        refProduitImage1.current.value = null;
        refProduitImage2.current.value = null;
        refProduitImage3.current.value = null;
        refProduitImage4.current.value = null;
        refProduitSerial.current.focus();
    };
    const handleImgchange = (e) => {
        const fImgprincipale = e.target.files[0];
        if (fImgprincipale) {
            const readerImprincipale = new FileReader();
            readerImprincipale.onloadend = () => {
                const base64Iprincipale = readerImprincipale.result;
                setImageprincipale(base64Iprincipale);
            }
            readerImprincipale.readAsDataURL(fImgprincipale);
        }
    };
    const handleImg1change = (e) => {
        const fImg1 = e.target.files[0];
        if (fImg1) {
            const readerIm1 = new FileReader();
            readerIm1.onloadend = () => {
                const base64Iprincipale = readerIm1.result;
                setImage1(base64Iprincipale);
            }
            readerIm1.readAsDataURL(fImg1);
        }
    };
    const handleImg2change = (e) => {
        const fImg2 = e.target.files[0];
        if (fImg2) {
            const readerIm2 = new FileReader();
            readerIm2.onloadend = () => {
                const base64Iprincipale = readerIm2.result;
                setImage2(base64Iprincipale);
            }
            readerIm2.readAsDataURL(fImg2);
        }
    };
    const handleImg3change = (e) => {
        const fImg3 = e.target.files[0];
        if (fImg3) {
            const readerIm3 = new FileReader();
            readerIm3.onloadend = () => {
                const base64Iprincipale = readerIm3.result;
                setImage3(base64Iprincipale);
            }
            readerIm3.readAsDataURL(fImg3);
        }
    };
    const handleImg4change = (e) => {
        const fImg4 = e.target.files[0];
        if (fImg4) {
            const readerIm4 = new FileReader();
            readerIm4.onloadend = () => {
                const base64Iprincipale = readerIm4.result;
                setImage4(base64Iprincipale);
            }
            readerIm4.readAsDataURL(fImg4);
        }
    };
    function fillBoutiqueProduit(d) {
        alert('Nouveau produit enregistré avec succès !');
        clearformNewProduit(); 
        getDataboutique(); 
        setNewp(false);      
    }
    function fillBoutiqueData(b, p, c) {
        setDataboutique(b);
        setDatacommandes(c);
        setDataboutiquetotalpage(p);
    }
    function setSectionproduits() {
        setSectionview(0);
        getDataboutique();
    }
    function setSectioncommandes() {
        setSectionview(1);
        getDataboutique();
    }
    function setSectionclients() {
        setSectionview(2);
    }
    function setSectionabonnements() {
        setSectionview(3);
    }
    function pageSuivante() {
        if (cPage < dataBoutiqueTotalpage) {
            setCpage(cPage+1);
        }
    }
    function pagePrecedente() {
        if (cPage > 1) {
            setCpage(cPage-1);
        }
    }
    function intValueHandle(v) {
        const nv = v.target.value;
        if (/^\d*$/.test(nv)) {
            setTxtproduitprix(nv);
        }
    }
    async function deleteProduitStock(id) {
        axios.post('https://api.hermannlepatriote.com/_010111SQL_/effacerunproduitdelaboutique', {id})
        .then(response => {
            switch (response.data.erreur) {
                case 'ErreurSuppressionProduit': 
                    return alert('Erreur de suppression'+response.data.message);
                case 'SuccessSuppressionProduit':
                    return getDataboutique();
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur de suppression du produit',error);
        });
    } 
    function addNewproduct() {
        setNewp(true);
    }
    function closeEnregistrerproduit() {
        clearformNewProduit();
        setNewp(false);
    }
    async function validerCommande(id) {
        axios.post('https://api.hermannlepatriote.com/_010111SQL_/validerlivraison', {id})
        .then(response => {
            switch (response.data.erreur) {
                case 'ErreurLivraison': 
                    return alert('Erreur de validation'+response.data.message);
                case 'SuccessLivraison':
                    return getDataboutique();
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur traitement de la commande',error);
        });
    }
    async function annulerCommande(id) {
        axios.post('https://api.hermannlepatriote.com/_010111SQL_/annulerlivraison', {id})
        .then(response => {
            switch (response.data.erreur) {
                case 'ErreurLivraison': 
                    return alert('Erreur de annuluation'+response.data.message);
                case 'SuccessLivraison':
                    return getDataboutique();
                default:
                    return null;
            }
        })
        .catch(error => {
            console.error('Erreur de traitement de la commande',error);
        });
    }


	return (
	    <div className="flex flex-col">
	    	<div className="w-full flex flex-col">
				<div className="w-screen bg-blue-800 text-white flex flex-wrap py-2">
					<div className="px-3 flex justify-between items-center" id="page-top-center">
						<div className="flex flex-wrap md:text-xs sm:text-xs items-center">
							<span>{dateEncours}</span>
                            <button onClick={hermannlepatrioteClientlogout} className="bg-white rounded-md ml-6">
                                <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd"/>
                                </svg>
                            </button>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col bg-gray-50 pt-12">
				<div className="w-full flex justify-center flex-col">
					<div className="w-full flex justify-center">
						<div className="w-6/12 bg-white rounded-2xl flex flex-wrap">
						</div>
					</div>
					<div className="border-b w-10/12 border-gray-400 my-5 self-center"></div>
                    <div className="w-full flex w-admin-menu px-6 mb-6">
                        <button onClick={() => setSectionproduits()} className={sectionView == 0 ? 'bg-blue-400 text-white p-3 mx-3 rounded-md duration-400 transition-all shadow' : 'btn-admin-menu p-3 mx-3 rounded-md hover:bg-blue-400 hover:text-white duration-400 transition-all shadow'}>
                            Les produits
                        </button>
                        <button onClick={() => setSectioncommandes()} className={sectionView == 1 ? 'bg-blue-400 text-white p-3 mx-3 rounded-md duration-400 transition-all shadow' : 'btn-admin-menu p-3 mx-3 rounded-md hover:bg-blue-400 hover:text-white duration-400 transition-all shadow'}>
                            Les Commandes
                        </button>
                        <button onClick={() => setSectionclients()} className={sectionView == 2 ? 'bg-blue-400 text-white p-3 mx-3 rounded-md duration-400 transition-all shadow' : 'btn-admin-menu p-3 mx-3 rounded-md hover:bg-blue-400 hover:text-white duration-400 transition-all shadow'}>
                            Les clients
                        </button>
                        <button onClick={() => setSectionabonnements()} className={sectionView == 3 ? 'bg-blue-400 text-white p-3 mx-3 rounded-md duration-400 transition-all shadow' : 'btn-admin-menu p-3 mx-3 rounded-md hover:bg-blue-400 hover:text-white duration-400 transition-all shadow'}>
                            Abonnements
                        </button>
                    </div>
                    <section className={(sectionView == 0) ? 'duration-500 transition-all' : 'hidden'} id="w-admin-produits">
                        <div className={setNewproduct ? 'w-full bg-gray-200 p-6' : 'hidden'}>
                            <button className="underline text-red-500 shadow bg-white px-1 float-right rounded-md" onClick={() => closeEnregistrerproduit()}>
                                Fermer
                            </button>
                            <div className="w-full flex flex-col bg-gray-300 p-2">
                                <div className="w-full w-adminproduits-form1">
                                    <div id="txt-adminproduits-serial">
                                        <input type="text" ref={refProduitSerial} value={txtProduitSerial} onChange={(e) => setTxtproduitserial(e.target.value)} className="w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" placeholder="Numéro de série" />
                                    </div>
                                    <div id="txt-adminproduits-nom">
                                        <input type="text" ref={refProduitNom} value={txtProduitNom} onChange={(e) => setTxtproduitnom(e.target.value)} className="w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" placeholder="Nom" />
                                    </div>
                                    <div id="txt-adminproduits-prix">
                                        <input ref={refProduitPrix} value={txtProduitPrix} onChange={intValueHandle} type="text" className="w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" placeholder="Prix" />
                                    </div>
                                </div>
                                <div className="w-full flex flex-wrap mb-8">
                                    <div id="txt-adminproduits-details">
                                        <ReactQuill id="editor-quill" value={txtProduitDetail} onChange={setTxtproduitdetail} modules={{ toolbar: [ [{'header': '1'}, {'header':'2'}, {'font': []}], [{'list':'ordered'}, {'list': 'bullet'}], ['bold', 'italic', 'underline'], ['link', 'image'], [{'align': []}], ['clean']] }} formats={[ 'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'link', 'image', 'align']} />
                                    </div>
                                </div>
                                <div className="w-full w-adminproducts-form2">
                                    <div id="txt-adminproduits-stock">
                                        <input ref={refProduitStock} value={txtProduitStock} onChange={(e) => setTxtproduitstock(e.target.value)} type="text" className=" w-full mb-2 rounded-md outline-none border-gray-200 px-2 py-1 text-gray-800" placeholder="Stock" />
                                    </div>
                                    <div id="txt-adminproduits-folder">
                                        image principale
                                        <input ref={refProduitImageprincipale} type="file" accept="image/*" onChange={handleImgchange} />
                                    </div>
                                    <div id="txt-adminproduits-folder">
                                        image 1
                                        <input ref={refProduitImage1} type="file" accept="image/*" onChange={handleImg1change} />
                                    </div>
                                    <div id="txt-adminproduits-folder">
                                        image 2 
                                        <input ref={refProduitImage2} type="file" accept="image/*" onChange={handleImg2change} />
                                    </div>
                                    <div id="txt-adminproduits-folder">
                                        image 3
                                        <input ref={refProduitImage3} type="file" accept="image/*" onChange={handleImg3change} />
                                    </div>
                                    <div id="txt-adminproduits-folder">
                                        image 4
                                        <input ref={refProduitImage4} type="file" accept="image/*" onChange={handleImg4change} />
                                    </div>
                                </div>
                                <div className="w-full flex flex-wrap justify-between">
                                    <button onClick={() => enregistrerNewProduit()} className="w-5/12 py-2 rounded bg-green-400 text-white font-bold text-xl hover:bg-green-600">
                                        Enregistrer
                                    </button>
                                    <button onClick={() => clearformNewProduit()} className="w-5/12 py-2 rounded bg-purple-900 text-white font-bold text-xl hover:bg-purple-600">
                                        Effacer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full p-6">
                            <button onClick={() => addNewproduct()} className="bg-indigo-500 text-white px-2 py-1 rounded flex items-center">
                                <svg className="w-7 h-7 text-white mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
                                </svg>
                                Ajouter un produit
                            </button>
                            <ul className="w-full rounded bg-white p-6">
                                {
                                    dataBoutique && dataBoutique.length > 0 ? (
                                        dataBoutique.map((prod) => (
                                            <li className="bg-white p-3 flex flex-wrap items-center mb-3 shadow">
                                                <p className="w-adminproduits-nom">
                                                    {prod.boutique_nom}
                                                </p>
                                                <img className="w-adminproduits-image" src={prod.boutique_imageprincipale} alt={prod.boutique_imageprincipale} />
                                                <p className="w-adminproduits-description">
                                                    <div dangerouslySetInnerHTML={{ __html: prod.boutique_details}}/>
                                                </p>
                                                <p className="w-adminproduits-prix flex justify-center">
                                                    ${prod.boutique_prix}
                                                </p>
                                                <p className="w-adminproduits-zoneqte">
                                                    <button className="hidden">
                                                        <svg className="w-7 h-7 text-gray-500 transition-all duration-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm5.757-1a1 1 0 1 0 0 2h8.486a1 1 0 1 0 0-2H7.757Z" clip-rule="evenodd"/>
                                                        </svg>
                                                    </button>
                                                    <span className="p-3 mx-1 rounded-3xl outline-none font-bold text-xs text-center bg-gray-300">
                                                        {prod.boutique_stock}
                                                    </span>
                                                    <button className="hidden">
                                                        <svg className="w-7 h-7 text-gray-500 transition-all duration-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4.243a1 1 0 1 0-2 0V11H7.757a1 1 0 1 0 0 2H11v3.243a1 1 0 1 0 2 0V13h3.243a1 1 0 1 0 0-2H13V7.757Z" clip-rule="evenodd"/>
                                                        </svg>
                                                    </button>
                                                </p>
                                                <p className="w-adminproducts-options flex items-center justify-center flex-wrap">
                                                    <button className="hidden">
                                                        <svg className="w-8 h-8 text-blue-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill-rule="evenodd" d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z" clip-rule="evenodd"/>
                                                            <path fill-rule="evenodd" d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z" clip-rule="evenodd"/>
                                                        </svg>
                                                    </button>
                                                    <button className="border rounded-full p-2 bg-gray-100 hover:bg-red-100 duration-400 transition-all" onClick={() => deleteProduitStock(`${prod.boutique_serial}`)}>
                                                        <svg className="w-6 h-6 text-red-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                            <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd"/>
                                                        </svg>
                                                    </button>
                                                </p>
                                            </li>
                                        ))
                                    ) : (
                                        <div>
                                            Pas de produits
                                        </div>
                                    )
                                }
                            </ul>
                            <div className="w-adminpagination-content">
                                <button onClick={pagePrecedente} className="mx-2 bg-gray-300 px-3 py-1 rounded-xl duration-500 transition-all hover:bg-gray-400 hover:shadow">
                                    <svg className="w-8 h-7 text-indigo-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                                    </svg>
                                </button>
                                <span>Page {cPage} sur {dataBoutiqueTotalpage}</span>
                                <button onClick={pageSuivante} className="mx-2 bg-gray-300 px-3 py-1 rounded-xl duration-500 transition-all hover:bg-gray-400 hover:shadow">
                                    <svg className="w-8 h-7 text-indigo-500"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </section>
                    <section className={(sectionView == 1) ? 'duration-500 transition-all' : 'hidden'} id="w-admin-commandes">
                        <div className="w-full p-6">
                            <ul className="w-full rounded bg-white p-6">
                                {
                                    dataCommandes && dataCommandes.length > 0 ? (
                                        dataCommandes.map((cmd) => (
                                            <li className={cmd.etat == 2 ? 'bg-gray-400 p-3 flex flex-wrap items-center mb-3' : 'bg-white p-3 flex flex-wrap items-center mb-3 shadow'}>
                                                <div className="w-admincmd-nom">
                                                    - {cmd.nom}<br/>
                                                    - {cmd.email}<br/>
                                                    - {cmd.tel1}
                                                </div>
                                                <div className="w-adminproduits-image">
                                                    - {cmd.adresse}<br/>
                                                    - {cmd.commentaires}<br/>
                                                    - <span className="bg-blue-300 px-2">{cmd.etat == 1 ? 'En cours' : 'Terminée'}</span>
                                                </div>
                                                <p className="w-adminproduits-description">
                                                    <AffichagePanier idPanier={cmd.panier} />
                                                </p>
                                                <p className="w-adminproduits-prix flex justify-center">
                                                    {cmd.qte}
                                                </p>
                                                <p className="w-adminproduits-zoneqte">
                                                    <span className="p-2 mx-1 rounded-md outline-none font-bold text-2xl text-center bg-orange-300">
                                                        ${cmd.cout}
                                                    </span>
                                                </p>
                                                <p className={cmd.etat == 2 ? 'hidden' : 'w-adminproducts-options flex items-center justify-center flex-wrap'}>
                                                    <button onClick={() => validerCommande(`${cmd.numero}`)} className={cmd.etat == 0 ? 'hidden' : 'bg-green-400 px-2 py-1 rounded text-white mb-2 mr-3'}>
                                                        Livraison
                                                    </button>
                                                    <button onClick={() => annulerCommande(`${cmd.numero}`)} className="bg-red-400 px-2 py-1 rounded text-white">
                                                        Annuler
                                                    </button>
                                                </p>
                                            </li>
                                        ))
                                    ) : (
                                        <div>
                                            Pas de commandes
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                    </section>
                    <section className={(sectionView == 2) ? 'duration-500 transition-all p-12 my-32 flex items-center justify-center' : 'hidden'} id="w-admin-clients">
                        gestion des clients
                    </section>
                    <section className={(sectionView == 3) ? 'duration-500 transition-all p-12 my-32 flex items-center justify-center' : 'hidden'} id="w-admin-abonnements">
                        gestion des abonnées
                    </section>
				</div>
			</div>
	    	<PageFooter />
		</div>
  	);
}

export default PageAdmin;
